<template>
  <div>
    <b-card>
      <div>
        <div class="d-flex justify-content-between">
          <div class="mb-1">
            <b-card-title class="mb-1 font-weight-bolder">
              變更密碼
            </b-card-title>
          </div>

          <div v-if="!userProfileState.isBusyLoading">
            <div
              v-b-tooltip.hover.focus.v-secondary
              title="變更密碼"
              class="d-flex align-items-center actions-link-btn ml-25"
              @click="handleOk"
            >
              <b-img
                src="/dashboard/admin/images/table/save.svg"
                class="actions-link-btn-image"
              />
            </div>
          </div>
        </div>

        <div>
          <validation-observer
            #default="{ handleSubmit }"
            ref="refFormObserver"
          >
            <b-form
              @submit.prevent="handleSubmit(onSubmit)"
              @reset.prevent="resetForm"
            >
              <b-row>
                <b-col
                  cols="12"
                  md="6"
                >
                  <!-- 舊密碼 -->
                  <validation-provider
                    #default="{ errors }"
                    name="舊密碼"
                    rules="required"
                  >

                    <b-form-group label-for="userPassword-pastPassword">
                      <template #label>
                        <div class="d-flex justify-content-between align-items-center">
                          <label class="mb-0">
                            舊密碼
                            <span class="text-danger">*</span>
                          </label>
                        </div>
                      </template>

                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid':null"
                      >
                        <b-form-input
                          id="userPassword-pastPassword"
                          v-model="userPassword.pastPassword"
                          :type="passwordFieldType[0]"
                          :state="errors.length > 0 ? false : null"
                          class="form-control-merge"
                          name="userPassword-pastPassword"
                          placeholder="請輸入舊密碼"
                        />
                        <b-input-group-append is-text>
                          <feather-icon
                            :icon="passwordToggleIcon(0)"
                            class="cursor-pointer"
                            @click="togglePassword(0)"
                          />
                        </b-input-group-append>
                      </b-input-group>

                      <b-form-invalid-feedback>
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col
                  cols="12"
                  md="6"
                >
                  <div>
                    <!-- 新密碼 -->
                    <validation-provider
                      #default="{ errors }"
                      name="新密碼"
                      rules="required"
                    >

                      <b-form-group label-for="userPassword-newPassword">
                        <template #label>
                          <div class="d-flex justify-content-between align-items-center">
                            <label class="mb-0">
                              新密碼
                              <span class="text-danger">*</span>
                            </label>
                          </div>
                        </template>

                        <b-input-group
                          class="input-group-merge"
                          :class="errors.length > 0 ? 'is-invalid':null"
                        >
                          <b-form-input
                            id="userPassword-newPassword"
                            v-model="userPassword.newPassword"
                            :type="passwordFieldType[1]"
                            :state="errors.length > 0 ? false : null"
                            class="form-control-merge"
                            name="userPassword-newPassword"
                            placeholder="請輸入新密碼"
                          />
                          <b-input-group-append is-text>
                            <feather-icon
                              :icon="passwordToggleIcon(1)"
                              class="cursor-pointer"
                              @click="togglePassword(1)"
                            />
                          </b-input-group-append>
                        </b-input-group>

                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>

                      <!-- 密碼驗證 -->
                      <div class="power-tips">
                        <b-row class="tips">
                          <b-col
                            class="tips-item"
                            :class="(userRegPwdLevel >= 1 && userPassword.newPassword) ? `${passwordStyle(userRegPwdLevel)}` : ''"
                          />

                          <b-col
                            class="tips-item"
                            :class="(userRegPwdLevel >= 2) ? `${passwordStyle(userRegPwdLevel)}` : ''"
                          />

                          <b-col
                            class="tips-item"
                            :class="(userRegPwdLevel >= 3) ? `${passwordStyle(userRegPwdLevel)}` : ''"
                          />

                          <b-col
                            class="tips-item"
                            :class="(userRegPwdLevel >= 4) ? `${passwordStyle(userRegPwdLevel)}` : ''"
                          />
                        </b-row>

                        <b-row class="tips">
                          <b-col>
                            <div
                              v-if="userRegPwdLevel === 1"
                              class="text-center"
                            >
                              <span>弱</span>
                            </div>
                          </b-col>

                          <b-col>
                            <div
                              v-if="userRegPwdLevel === 2"
                              class="text-center"
                            >
                              <span>中</span>
                            </div>
                          </b-col>

                          <b-col>
                            <div
                              v-if="userRegPwdLevel === 3"
                              class="text-center"
                            >
                              <span>強</span>
                            </div>
                          </b-col>

                          <b-col>
                            <div
                              v-if="userRegPwdLevel === 4"
                              class="text-center"
                            >
                              <span>很強</span>
                            </div>
                          </b-col>
                        </b-row>
                      </div>
                    </validation-provider>
                  </div>

                  <div>
                    <!-- 重複新密碼 -->
                    <validation-provider
                      #default="{ errors }"
                      name="重複新密碼"
                      rules="required"
                    >

                      <b-form-group label-for="userPassword-repeatPassword">
                        <template #label>
                          <div class="d-flex justify-content-between align-items-center">
                            <label class="mb-0">
                              重複新密碼
                              <span class="text-danger">*</span>
                            </label>
                          </div>
                        </template>

                        <b-input-group
                          class="input-group-merge"
                          :class="errors.length > 0 ? 'is-invalid':null"
                        >
                          <b-form-input
                            id="userPassword-repeatPassword"
                            v-model="userPassword.repeatPassword"
                            :type="passwordFieldType[2]"
                            :state="errors.length > 0 ? false : null"
                            class="form-control-merge"
                            name="userPassword-repeatPassword"
                            placeholder="請重複輸入新密碼"
                          />
                          <b-input-group-append is-text>
                            <feather-icon
                              :icon="passwordToggleIcon(2)"
                              class="cursor-pointer"
                              @click="togglePassword(2)"
                            />
                          </b-input-group-append>
                        </b-input-group>

                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </div>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>

        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { required, password } from '@validations'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import {
  BRow, BCol, BCardTitle, BForm, VBToggle, BFormInvalidFeedback, BFormGroup, BFormInput, BInputGroupAppend,
  BInputGroup, VBTooltip, BImg, BCard,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import formValidation from '@core/comp-functions/forms/form-validation'
import { useProfileSetting, useProfileFun } from '../useProfile'
import { useSwalToast, useAlert } from '@/libs/mixins/index'
import store from '@/store'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BRow,
    BCol,
    BImg,
    BCard,
    BCardTitle,
    BForm,
    BFormInvalidFeedback,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,

    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  mixins: [useSwalToast],
  data() {
    return {
      required,
      password,
      missingFields: [],
      passwordFieldType: ['password', 'text', 'password'],
    }
  },
  computed: {
    userProfileState() {
      return this.$store.state['user-profile']
    },
  },
  methods: {
    // 顯示密碼/隱藏密碼
    togglePassword(index) {
      const newValue = this.passwordFieldType[index] === 'password' ? 'text' : 'password'
      this.passwordFieldType.splice(index, 1, newValue)
    },
    // 顯示密碼/隱藏密碼 - ICON
    passwordToggleIcon(index) {
      return this.passwordFieldType[index] === 'password' ? 'EyeOffIcon' : 'EyeIcon'
    },

    // 按下彈窗確認
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()

      // 判斷必填欄位是否填寫
      const requiredFields = {
        pastPassword: '舊密碼',
        newPassword: '新密碼',
        repeatPassword: '確認新密碼',
      }

      this.missingFields = []

      for (let i = 0; i < Object.keys(requiredFields).length; i += 1) {
        if (this.userPassword[Object.keys(requiredFields)[i]] === null || this.userPassword[Object.keys(requiredFields)[i]] === '') {
          this.missingFields.push({
            label: Object.values(requiredFields)[i],
            value: Object.keys(requiredFields)[i],
          })
        }
      }

      if (this.missingFields.length > 0) {
        this.useAlertToast(false, `${this.missingFields[0].label}不得為空`)
        return
      }
      if (this.userPassword.newPassword !== this.userPassword.repeatPassword) {
        this.useAlertToast(false, '新密碼與確認新密碼不一致')
        return
      }

      this.isBusy = true

      const resolveData = {
        pastPassword: this.userPassword.pastPassword,
        newPassword: this.userPassword.newPassword,
      }

      this.$swal({
        title: '變更密碼',
        text: '修改密碼將會導致您需要重新使用新密碼登入此帳號。請問您是否要進行此操作？',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: '確定',
        cancelButtonText: '取消',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-secondary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.setUserPasswordChange({
            ...resolveData,
          })
            .then(response => {
              this.useHttpCodeAlert(response)
              store.dispatch('api/logout')
              localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
              localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)
              localStorage.removeItem('userData')
              this.$store.commit('app/UPDATE_USERDATA_STATE', null)
              this.$router.push({ name: 'page-login' })
            })
            .catch(error => {
              this.useHttpCodeAlert(error.response)
              this.isBusy = false
            })
        } else this.isBusy = false
      })
    },
  },
  setup() {
    localize('tw')

    const {
      syncObject,
    } = useProfileSetting()

    const blankPasswordData = {
      pastPassword: '',
      newPassword: '',
      repeatPassword: '',
    }

    const userPassword = ref(JSON.parse(JSON.stringify(blankPasswordData)))
    const userRegPwdLevel = ref(0)
    const isBusy = ref(false)

    const resetUserPassword = () => {
      userPassword.value = JSON.parse(JSON.stringify(blankPasswordData))
      userRegPwdLevel.value = 0
      isBusy.value = false
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetUserPassword)

    const lengthWeakness = psw => {
      const { length } = psw

      if (length <= 5) {
        return {
          message: '你的密碼太短',
          deduction: 40,
        }
      }

      if (length <= 10) {
        return {
          message: '你的密碼可以再長一點',
          deduction: 15,
        }
      }

      if (length <= 15) {
        return {
          message: '',
          deduction: -20,
        }
      }
      return null
    }

    const characterTypeWeakness = (psw, regex, type) => {
      const matches = psw.match(regex) || []

      if (matches.length === 0) {
        return {
          message: `你的密碼沒有 ${type}`,
          deduction: 20,
        }
      }

      if (matches.length <= 2) {
        return {
          message: `你的密碼可以用更多 ${type}`,
          deduction: 5,
        }
      }
      return null
    }

    const repeatCharactersWeakness = psw => {
      const matches = psw.match(/(.)\1/g) || []
      if (matches.length > 0) {
        return {
          message: 'Your password has repeat characters',
          deduction: matches.length * 10,
        }
      }
      return null
    }

    const calculatePasswordStrength = psw => {
      const weaknesses = []
      weaknesses.push(lengthWeakness(psw))
      weaknesses.push(characterTypeWeakness(psw, /[A-Z]/g, '大寫英文'))
      weaknesses.push(characterTypeWeakness(psw, /[a-z]/g, '小寫英文'))
      weaknesses.push(characterTypeWeakness(psw, /[0-9]/g, '數字'))
      weaknesses.push(characterTypeWeakness(psw, /[^0-9a-zA-Z\s]/g, '特殊字元'))
      weaknesses.push(repeatCharactersWeakness(psw))
      return weaknesses
    }

    const passwordCheck = value => {
      const weaknesses = calculatePasswordStrength(value)
      let strength = 100
      weaknesses.forEach(weakness => {
        if (weakness == null) return
        strength -= weakness.deduction
        const messageElement = document.createElement('div')
        messageElement.innerText = weakness.message
      })
      switch (true) {
        case strength > 90: {
          userRegPwdLevel.value = 4
          break
        }
        case strength > 49: {
          userRegPwdLevel.value = 3
          break
        }
        case strength > 20: {
          userRegPwdLevel.value = 2
          break
        }
        default:
          userRegPwdLevel.value = 1
          break
      }
      // console.log('密碼分數:', strength)
    }

    watch(() => userPassword.value.newPassword, (newValue, oldValue) => {
      if (newValue !== oldValue) {
        passwordCheck(newValue)
        // console.log('密碼級距:', userRegPwdLevel.value)
      }
    })

    const passwordStyle = state => {
      if (state === 1) return 's1'
      if (state === 2) return 's2'
      if (state === 3) return 's3'
      if (state === 4) return 's4'
      return ''
    }

    const {
      useAlertToast,
      useHttpCodeAlert,
    } = useAlert()

    const {
      setUserPasswordChange,
    } = useProfileFun()

    return {
      isBusy,

      userPassword,
      userRegPwdLevel,
      passwordStyle,
      passwordCheck,
      blankPasswordData,
      // setPasswordChange,

      resetUserPassword,
      refFormObserver,
      getValidationState,
      resetForm,

      useHttpCodeAlert,
      useAlertToast,
      syncObject,
      setUserPasswordChange,
    }
  },
}
</script>

<style lang="scss" scoped>
</style>

<!-- 密碼強度-SCSS -->
<style lang="scss" scoped>
.power-tips {
  height: 40px;
}
.tips {
  margin: 5px 0 5px 0;
  .tips-item {
    margin-right: 5px;
    height: 8px;
    border-radius: 5px;
    background: #d7d9dd;
  }
  .s1 {
    background: #ea5455;
  }
  .s2 {
    background: #ff9f43;
  }
  .s3 {
    background: #ffc107;
  }
  .s4 {
    background: #48cf75;
  }
}
</style>
